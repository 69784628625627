import { defineStore } from "pinia";
import { useConfigStore } from "~/stores/config";
import { useNuxtApp } from "#app";
import { useUserStore } from "~/stores/user";

/*
 * Declaration of items in the sidebar with their name, icons and urls.
 * When adding a new item ensure that the url is also declared in router.js
 * (found in the root folder.)
 */

/* ICONS
* Icons can be used from the following libraries:
* - from the creative tim template:
*     - link:
*     - usage: <i class="nc-icon nc-bank">face</i>
* - font awesome:
*     - link: https://fontawesome.com/icons?d=gallery&m=free
*     - usage: <i class="fa fa-camera"></i>
* - material design icons:
*     - link: https://material.io/resources/icons
*     - usage: <i class="material-icons">face</i>

* material design icons: example:
* */

const sidebarLinks = [
  // {name: 'Dashboard', icon_class: 'fa fa-dashboard', abbreviation: 'D',
  // path: '/'}
  {
    name: "Equipment",
    icon_class: "fa fa-cogs",
    children: [
      { name: "Equipment", abbreviation: "E", path: "/machine/list" },
      { name: "Families", abbreviation: "MF", path: "/machinefamily/list" },
      {
        name: "Bookings",
        abbreviation: "B",
        path: "/machine/bookings",
        feature: "bookings"
      }
    ]
  },
  {
    name: "Lockers",
    icon_class: "fa fa-archive",
    feature: "lockers",
    children: [
      {
        name: "Lockers",
        abbreviation: "M",
        path: "/locker/list",
        feature: "lockers"
      },
      { name: "Families", abbreviation: "F", path: "/toolfamily/list" }
    ],
  },
  {
    name: "Activities",
    icon_class: "fa fa-cubes",
    children: [
      { name: "Jobs", abbreviation: "J", path: "/job/list" },
      { name: "Issues", abbreviation: "I", path: "/issue/list" }
    ]
  },
  {
    type: "divider",
    name: "DIVIDER",
    icon_class: "fa fa-user",
    path: "/user/list"
  },
  {
    name: "Users",
    icon_class: "fa fa-user",
    children: [
      { name: "Users", abbreviation: "U", path: "/user/list" },
      { name: "Usergroups", abbreviation: "UG", path: "/usergroup/list" }
    ]
  },
  {
    name: "Staff",
    icon_class: "fa fa-users",
    children: [
      { name: "Members", abbreviation: "M", path: "/staffmember/list" },
    ]
  },
  {
    name: "Owners",
    icon_class: "fa fa-lightbulb-o",
    children: [
      { name: "Owners", abbreviation: "OW", path: "/workspace/list" }
    ]
  }
];

export const useSidebarStore = defineStore("sidebar", {
  state: () => ({
    unfiltered_links: sidebarLinks,
    dashboard_url: "",
    user: {},
    showSidebar: false,
    isMinimized: false,
  }),
  getters: {
    filtered_links(state) {
      const links = this.get_allowed_sidebar_links();
      const reportLink = this.get_reports_link();

      if (reportLink) {
        links.push(reportLink);
      }

      return links;
    }
  },
  actions: {
    get_reports_link() {
      const user = useUserStore();
      const config = useConfigStore();
      try {
        if (!user.is_lab_manager()) return false;
        if (!user.tenant_config?.dashboard_url) return false;

        return {
          name: "Reports",
          icon_class: "fa fa-bar-chart",
          children: [{
            name: "Reports",
            abbreviation: "R",
            path: config.dashboard_url,
            target: "_blank"
          }]
        };
      } catch (err) {
        console.error(err);
      }
    },
    has_path_permission(user, allowed_permissions) {
      return allowed_permissions?.filter(
        permission => {
          const [permission_class, permission_op] = permission.split(".");
          return user.permissions?.[permission_class]?.[permission_op];
        }).length > 0;
    },
    path_authorized(user, link) {
      // Determine if a given path is authorized based on the allowed_roles
      // and allowed_permissions associated to it and the user roles and
      // permissions

      const config = useConfigStore();
      if ("feature" in link && !config.has_feature(link.feature)) {
        return false
      }

      const nuxtApp = useNuxtApp();
      const route = nuxtApp.$router.resolve({ path: link.path });
      const route_props = route?.matched[0]?.props.default;

      if (
        route_props?.public ||
        route_props?.allowed_roles.filter(
            role => user.has_role(role)
          ).length > 0 ||
        this.has_path_permission(
            user,
            route_props?.allowed_permissions
          )
      ) {
        return true;
      }
      return false;
    },
    get_allowed_sidebar_links() {
      /*
       * Determine a list of allowed links based on the allowed_roles and
       * allowed_permissions properties in the router definition and
       * the user roles and persmissions declared in the Pinia user store.
       */
      const user = useUserStore();

      function copy(o) {
        return {...o};
      }

      return this.unfiltered_links.map(copy).filter((link) => {
        // Do not allow access if the full block is relevant to this feature

        const config = useConfigStore();
        if ("feature" in link && !config.has_feature(link.feature)) {
          return false;
        }

        if (link.children) {
          link.children = link.children.filter(
            (child: any
            ) => this.path_authorized(user, child)
          );
          return link.children.length > 0;
        }
        return this.path_authorized(user, link);
      });
    },
    displaySidebar(value:boolean) {
      this.showSidebar = value
    },
    toggleMinimize() {
      document.body.classList.toggle('sidebar-mini')
      // we simulate the window Resize so the charts will get updated in
      // realtime.
      const simulateWindowResize = setInterval(() => {
        window.dispatchEvent(new Event('resize'))
      }, 180)

      // we stop the simulation of Window Resize after the animations are
      // completed
      setTimeout(() => {
        clearInterval(simulateWindowResize)
      }, 1000)

      this.isMinimized = !this.isMinimized
    }
  }
});
