<template>
  <div
    v-click-outside="onClickOutside"
    class="profile-wrapper"
  >
    <div
      v-if="user"
      id="user-menu-dropdown"
      :class="[{ open: closedState != 'closed' }, 'profile']"
    >
      <div class="info">
        <a
          :aria-expanded="!isClosed"
          class="name"
          data-toggle="collapse"
          href="#"
          @click="toggleMenu"
        >
          <div class="photo">
            <img
              v-if="is_superuser()"
              alt="User is superuser"
              src="~/assets/img/faces/superuser.png"
            >
            <img
              v-else
              alt="User icon"
              src="~/assets/img/faces/no-face.png"
            >
          </div>
          <span style="text-transform: capitalize; white-space: nowrap">
            {{ user.first_name }}
            <b class="caret" />
          </span>
        </a>
        <div class="clearfix" />
        <div>
          <collapse-transition
            @after-enter="transitionComplete"
            @after-leave="transitionComplete"
          >
            <ul
              v-show="!isClosed"
              class="nav nav-menu"
            >
              <li>
                <a href="/user/profile/edit">
                  <span class="sidebar-normal">My Profile</span>
                </a>
              </li>
              <li>
                <a href="/user/privacy">
                  <span class="sidebar-normal">My Privacy</span>
                </a>
              </li>
              <li>
                <a href="/user/settings/badge">
                  <span class="sidebar-normal">Register Badge</span>
                </a>
              </li>
              <li v-if="is_superuser()">
                <a
                  :href="sanitizeUrl(adminUrl)"
                  rel="noopener"
                  target="_blank"
                >
                  <span class="sidebar-normal">Backend</span>
                </a>
              </li>
              <li v-if="'impostor' in user && user['impostor'] === true">
                <a
                  id="su-logout-link"
                  href="#"
                  @click.prevent="su_logout"
                >
                  <span class="sidebar-normal">Sudo Logout</span>
                </a>
              </li>
              <li v-else>
                <a
                  id="logout-link"
                  href="#"
                  @click.prevent="logout"
                >
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </collapse-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapStores } from "pinia";
import { CollapseTransition } from "@noction/vue-bezier";
import { sanitizeUrl } from "@braintree/sanitize-url";
import { useAuthStore } from "~/stores/auth";
import { useConfigStore } from "~/stores/config";
import { useRouter } from "#app";
import { useUserStore } from "~/stores/user";
import vClickOutside from "click-outside-vue3";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    CollapseTransition,
  },
  data() {
    return {
      isClosed: true,
      closedState: "closed",
      alert: null,
    };
  },
  computed: {
    ...mapStores(useUserStore, useConfigStore),
    adminUrl() {
      return this.configStore.backend_url + "/admin";
    },
    user() {
      return this.userStore;
    },
  },
  watch: {
    user: function () {
      this.isClosed = true;
    },
  },
  methods: {
    sanitizeUrl,
    ...mapActions(useAuthStore, {
      _logout: "logout",
      _su_logout: "su_logout",
    }),
    onClickOutside() {
      if (!this.isClosed) {
        this.closedState = "closing";
        this.isClosed = true;
      }
    },
    toggleMenu() {
      if (this.isClosed) {
        this.closedState = "opening";
      } else {
        this.closedState = "closing";
      }
      this.isClosed = !this.isClosed;
    },
    clickOutside() {
      if (!this.isClosed) {
        this.closedState = "closing";
        this.isClosed = true;
      }
    },
    transitionComplete() {
      switch (this.closedState) {
        case "opening":
          break;
        case "closing":
          this.closedState = "closed";
          break;
      }
    },
    async logout() {
      await this._logout();
      const router = useRouter();
      router.push({ path: "/login" });
    },
    su_logout() {
      this._su_logout();
      this.onClickOutside();
    },
    is_superuser() {
      return this.userStore.is_superuser();
    },
  },
};
</script>

<style scoped>
.profile-wrapper {
  height: 54px;
  position: relative;
}

.profile {
  position: absolute;
  z-index: 999;
  right: 0;
}

.profile.open {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  color: #252422;
  border: 0 none;
  transition:
    transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
}

.name {
  padding: 10px;
  min-width: 150px;
}

.caret {
  margin-left: 10px;
}

.name,
.name span {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: left;
}

.photo {
  width: 34px;
  height: 34px;
  overflow: hidden;
  float: left;
  z-index: 5;
  margin-right: 10px;
  border-radius: 50%;
}

.nav.nav-menu {
  flex-flow: column;
}

.nav.nav-menu li a {
  white-space: nowrap;
  border-top: 1px solid #ebeef5;
  padding: 10px;
  display: block;
}

.nav.nav-menu li:last-child a {
  border-bottom: 1px solid #ebeef5;
}

.nav.nav-menu li a:hover {
  background-color: #f5f7fa;
  transition: background-color 0.25s ease;
}
</style>
