<template>
  <a
    :class="isActive"
    data-toggle="collapse"
    href="#"
    @click.prevent="collapseMenu"
  >
    <i :class="link.icon_class">{{ link.icon_name }}</i>
    <p>
      {{ link.name }}
      <b
        :class="{ rotated: !collapsed }"
        class="caret"
      />
    </p>
  </a>
  <collapse-transition v-if="$slots.default || true">
    <div
      v-show="!collapsed"
      class="collapse-menu"
    >
      <ul class="nav">
        <li
          v-for="(subLink, subindex) in link.children"
          :key="subLink.name + index + '_' + subindex"
        >
          <sidebar-item
            :link="subLink"
            @sidebar-close="sidebarClose"
          />
        </li>
      </ul>
    </div>
  </collapse-transition>
</template>

<script>
import { CollapseTransition } from "@noction/vue-bezier";
import Divider from "./SidebarDivider.vue";
import SidebarItem from "./SidebarItem.vue";

/* eslint-disable vue/no-unused-components */
export default {
  name: "SidebarMenu",
  components: {
    CollapseTransition,
    "sidebar-item": SidebarItem,
    divider: Divider,
  },
  props: {
    link: {
      type: Object,
      default: () => {
        return {
          name: "",
          icon_class: null,
          icon_name: null,
          path: "",
          children: [],
        };
      },
      description: "Link object",
    },
    index: {
      type: Number,
      default: 0,
      description: "Index for key",
    },
  },
  emits: ["sidebar-close"],
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    isActive() {
      if (this.$route) {
        return this.link.children.filter(
          (link) => link.path === this.$route.path,
        ).length > 0
          ? "active"
          : "";
      }
      return "";
    },
  },
  methods: {
    sidebarClose() {
      this.$emit("sidebar-close");
    },
    collapseMenu() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style scoped>
.caret.rotated {
  transform: rotate(180deg);
}
</style>
