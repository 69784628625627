<template>
  <hr :key="link.name + index">
</template>

<script>
export default {
  props: {
    link: {
      type: Object,
      default: () => {
        return {
          name: "",
          icon_class: null,
          icon_name: null,
          path: "",
          children: [],
        };
      },
      description: "Link object",
    },
    index: {
      type: Number,
      default: 0,
      description: "index for key",
    },
  },
};
</script>

<style scoped>
hr {
  margin: 10px 20px;
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
