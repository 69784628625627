<template>
  <main-navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-toggle">
        <navbar-toggle-button
          @click="toggleSidebar"
        />
      </div>
      <ClientOnly>
        <div
          v-if="has_history()"
          class="history-go-back"
        >
          <button
            id="navigateBack"
            class="btn btn-round"
            @click="navigateBack"
          >
            <i
              class="nc-icon
              nc-minimal-right
              text-center
              visible-on-sidebar-mini"
            />
            <i
              class="nc-icon
              nc-minimal-left
              text-center
              visible-on-sidebar-regular"
            />
            BACK
          </button>
        </div>
      </ClientOnly>
    </div>
    <user-profile />
    <template #navbar-menu />
  </main-navbar>
</template>
<script>
import {
  MainNavbar,
  NavbarToggleButton
} from "~/components/Library/UIComponents";

import UserProfile
  from "~/components/Library/UIComponents/Navbar/UserProfile.vue";
import { mapStores } from "pinia";
import { useSidebarStore } from "~/stores/sidebar";

export default {
  name: "TopNavbar",
  components: {
    "main-navbar": MainNavbar,
    NavbarToggleButton,
    UserProfile
  },
  emits: ["toggle-sidebar"],
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
    };
  },
  computed:{
    ...mapStores(useSidebarStore),
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.sidebarStore.displaySidebar(!this.sidebarStore.showSidebar);
    },
    hideSidebar() {
      this.sidebarStore.displaySidebar(false);
    },
    minimizeSidebar() {
      this.sidebarStore.toggleMinimize();
    },
    navigateBack() {
      this.$router.go(-1);
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    has_history() {
      if (process.client) {
        return window.history.length > 1;
      } else {
        return false;
      }
    }
  }
};
</script>
