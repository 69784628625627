<template>
  <div
    :data-active-color="activeColor"
    :data-color="backgroundColor"
    class="sidebar"
  >
    <div class="logo">
      <a
        class="simple-text logo-mini"
        href="#"
      >
        <div class="logo-img">
          <img
            alt="Toolsquare"
            src="~/assets/favicon.svg"
          >
        </div>
      </a>
      <a
        class="simple-text logo-normal"
        href="/"
      >
        {{ title }}
      </a>
    </div>
    <div
      ref="sidebarScrollArea"
      class="sidebar-wrapper"
    >
      <slot />
      <ul class="nav">
        <slot name="links">
          <li
            v-for="(link, index) in sidebarLinks"
            :key="'sidebar_block_' + index"
          >
            <component
              :is="link.type || 'sidebar-menu'"
              :index="index"
              :link="link"
              @sidebar-close="sidebarClose"
            />
          </li>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import Divider from "./SidebarDivider.vue";
import SidebarMenu from "./SidebarMenu.vue";
import { mapStores } from "pinia";
import { useSidebarStore } from "~/stores/sidebar";

export default {
  name: "SidebarMain",
  components: {
    SidebarMenu,
    Divider,
  },
  props: {
    title: {
      type: String,
      default: "Toolsquare",
      description: "Sidebar title",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        const acceptedValues = ["white", "brown", "black"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar background color (white|brown|black)",
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) => {
        const acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        "Sidebar active text color (primary|info|success|warning|danger)",
    },
    logo: {
      type: String,
      default: "/assets/img/favicon.svg",
      description: "Toolsquare Logo",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "Sidebar links. " +
        "Can be also provided as children components (sidebar-item)",
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapStores(useSidebarStore),
  },
  beforeUnmount() {
    if (this.sidebarStore.showSidebar) {
      this.sidebarStore.showSidebar = false;
    }
  },
  methods: {
    sidebarClose() {
      if (this.autoClose) {
        this.sidebarStore.showSidebar = false;
      }
    },
  },
};
</script>
<style>
.sidebar .logo .simple-text .logo-img {
  border-radius: 0;
  background: transparent;
}

.sidebar .logo .simple-text .logo-img img {
  height: 35px;
}

@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
