<template>
  <component
    :is="elementType(link, false)"
    :class="{ active: isActive }"
    :href="sanitizeUrl(link.path)"
    :target="link.target"
    :to="link.path"
    @click.stop="onItemClick"
  >
    <span class="sidebar-mini-icon">{{ getMiniIcon(link) }}</span>
    <span class="sidebar-normal">{{ link.name }}</span>
  </component>
</template>

<script>
import { sanitizeUrl } from "@braintree/sanitize-url";

export default {
  name: "SidebarItem",
  props: {
    link: {
      type: Object,
      default: () => {
        return {
          name: "",
          icon_class: null,
          icon_name: null,
          path: "",
          children: [],
          active: false,
        };
      },
      description: "Link object",
    },
  },
  emits: ["sidebar-close"],
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    isActive() {
      if (this.$route) {
        return this.link.path === this.$route.path;
      }
      return false;
    },
  },
  methods: {
    sanitizeUrl,
    elementType(link, isParent = true) {
      /**
       * If the provided path is an absolute url (both http or https) then we
       * use a simple anchor tag
       */
      if (link.path.startsWith("http")) {
        return "a";
      }
      if (link.isRoute === false) {
        return isParent ? "li" : "a";
      } else {
        return "router-link";
      }
    },
    onItemClick() {
      this.$emit("sidebar-close");
    },
    getMiniIcon(link) {
      if ("abbreviation" in link) {
        return link.abbreviation;
      } else {
        return link.name.substring(0, 2);
      }
    },
  },
};
</script>
